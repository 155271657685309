<template>
    <div class="resultPage">
        <img src="../../assets/invoice2.png" />
        <span class="title">提交失败</span>

        <span class="desc">{{status=='G'?"订单已开发票":"重新提交开票"}}</span>
        <Button v-if="status!='G'" class="mar-t-40" color="#2b72ff" block @click="submitAgain">重新提交</Button>      
  </div>
</template>

<script>
import { Button } from "vant";
    export default {
        name:'invoiceFailed',
        components:{Button},
        data(){
            return{
                status:''
            }
        },
        created(){
            this.status=this.$route.query.status;
        },
        methods:{
            submitAgain(){
                this.$router.go(-1);
            },            
        }
    }
</script>

<style lang="less" scoped>
@import "./common.less";
.resultPage{
    height: 100vh!important;
}
</style>